
.cmp-header {
    height: calc(498px - 56px);
    width: 100%;
    margin-top: 56px;
    background-image: url(https://static.retail.autofact.cl/surveys/banner-big.jpg);
    background-color: $base-theme-color-darkblue;
    background-repeat: no-repeat !important;
    background-size: cover;
    background-position: right;
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 22px;
        .logo-container {
          display: none;
        }
        h1 {
            width: 100%;
            max-width: 604px;
            text-align: center;
            letter-spacing: 0;
            color: #FFFFFF;
            opacity: 1;
            font-size: 25px;
            @include media-breakpoint-down(sm){
                padding-top: 0px;
            }
        }
    }

    @include media-breakpoint-up(md) {
        height: 498px;
        margin-top: 0;
        .container {
            .logo-container {
                display: block;
                width: 266px;
                padding: 30px;
            }
            .main-title {
                font-size: 38px;
                max-width: 100%;
            }
        }
    }
}
form.rjsf {
  font-family: Roboto, Arial;
  color: #7E7E7E;

  .form-group.field:not(.field-object) {
    margin-bottom: 30px;
  }

  label.control-label {
    font-family: Raleway, sans-serif;
    color: #0A3253;
    font-size: 17px;
    line-height: 1.3;
    font-weight: bold;

    span.required {
      display: none;
    }
  }

  .radio-inline {
    padding-right: 15px;
    cursor: pointer;

    input {
      margin-right: 5px;
    }
  }

  .btn.btn-info {
    background: #0CB7EF;
    font-family: Raleway, sans-serif;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 15px;
    border: none;
    padding: 7.5px 30px;

    &:hover {
      opacity: 0.7;
    }

    @include media-breakpoint-down(sm) {
      display: block;
      width: 100%;
    }
  }

  .checkboxes {
    label, input {
      cursor: pointer;
    }

    .checkbox span {
      padding-left: 5px;
    }
  }

  .field-radio-group {
    label, input {
      cursor: pointer;
    }

    .radio span {
      padding-left: 5px;
    }
  }

  .width-xs {
    input.form-control {
      max-width: 300px;
    }
  }
  label{
    user-select: none;
  }
}

/* make keyframes that tell the start state and the end state of our object */
@-webkit-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
@-moz-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
@keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
.fade-in {
    opacity:0;  /* make things invisible upon start */
    -webkit-animation:fadeIn ease-in 1;  /* call our keyframe named fadeIn, use animattion ease-in and repeat it only 1 time */
    -moz-animation:fadeIn ease-in 1;
    animation:fadeIn ease-in 1;
  
    -webkit-animation-fill-mode:forwards;  /* this makes sure that after animation is done we remain at the last keyframe value (opacity: 1)*/
    -moz-animation-fill-mode:forwards;
    animation-fill-mode:forwards;
  
    -webkit-animation-duration:1s;
    -moz-animation-duration:1s;
    animation-duration:1s;

    -webkit-animation-delay: 0.4s;
    -moz-animation-delay: 0.4s;
    animation-delay: 0.4s;
  }



@-webkit-keyframes bgColor {
  0% { background-color: #ABABAB; }
  20% { background-color: #7E7E7E; }
  40% { background-color: #ABABAB; }
  60% { background-color: #7E7E7E; }
  80% { background-color: #ABABAB; }
  100% { background-color: #7E7E7E; }
}
@keyframes bgColor {
  0% { background-color: #ABABAB; }
  20% { background-color: #7E7E7E; }
  40% { background-color: #ABABAB; }
  60% { background-color: #7E7E7E; }
  80% { background-color: #ABABAB; }
  100% { background-color: #7E7E7E; }
}
.bg-color-fade{
  background-color: #7E7E7E;
  animation: bgColor 7s infinite;
  -webkit-animation: bgColor 7s infinite;
}
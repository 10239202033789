.app {
  width: 100%;
  height: 100%;
  min-height: 100%;
  background-color: #EDEDED;
  .app-body {
    background-color: inherit;
    min-height: calc(100vh - (442px + 56px));
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .container {
      .custom-container {
        width: 100%;
        min-height: 300px;
        height: auto;
        border-top: 8px solid $base-theme-color-blue;
        position: relative;
        margin-top: -250px;
        background-color: $base-theme-color-white;
        padding: 30px;
        box-shadow: 0px 2px 4px $base-theme-color-box-shadow;
        opacity: 1;
      }
    }
    .btn.btn-info:disabled{
      pointer-events: none;
      background-color: $base-theme-color-lightgray !important;
    }


    .block {
      min-width: 200px;
      height: 25px;
      border-radius: 10px;
      background-color: $base-theme-color-lightgray;
      margin-bottom: 20px;
      &.large {
        width: 100% !important;
      }
      &.medium {
        width: 70% !important;
      }
      &.small {
        width: 40% !important;
      }
      &.table {
        height: 200px;
      }
    }
  }

  @include respond-to('medium') {
    .app-body {
      min-height: calc(100vh - 498px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .container {
        .custom-container {
          margin-top: -180px;
        }
      }
    }
  }
}
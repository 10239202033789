.cmp-footer {
    background-color: $base-theme-color-darkblue;
    width: 100%;
    height: 100px;
    margin-top: 1em;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    .logo-container {
        width: 200px;
        margin: auto;
    }

    @include respond-to('small') {}
    @include respond-to('medium') {}
    @include respond-to('large') {}
    @include respond-to('extra-large') {}
}
/** 
 * Responsive Manager
 * @access public
 * @param {String} $breakpoint
 * @requires $breakpoints
 */
@mixin respond-to($breakpoint) {
    $raw-query: map-get($breakpoints, $breakpoint);
    @if $raw-query {
        $query: if( type-of($raw-query)=='string', unquote($raw-query), inspect($raw-query));
        @media #{$query} {
            @content;
        }
    }
    @else {
        @error 'Not found any value for `#{$breakpoint}`. '+'Please, be shure it is defined in the map `$breakpoints`.';
    }
}
/*Fonts helpers*/
/*Roboto*/
.font-roboto {
  font-family: Roboto, sans-serif !important;
}

/*raleway*/
.font-raleway {
  font-family: "Raleway", sans-serif !important;
}

.font-light {
  font-weight: 300 !important;
}

.font-medium {
  font-weight: 500 !important;
}

.font-semibold {
  font-weight: 600 !important;
}

.font-bold {
  font-weight: 700 !important;
}

.font-black {
  font-weight: 900 !important;
}


/*sizes*/
.font-size-12 {
  &.force-font-size {
    font-size: 12px !important;
  }
  font-size: 12px;
}

.font-size-13 {
  &.force-font-size {
    font-size: 13px !important;
  }
  font-size: 13px;
}

.font-size-14 {
  &.force-font-size {
    font-size: 14px !important;
  }
  font-size: 14px;
}

.font-size-15 {
  &.force-font-size {
    font-size: 15px !important;
  }
  font-size: 15px;
}

.font-size-16 {
  &.force-font-size {
    font-size: 16px !important;
  }
  font-size: 16px;
}

.font-size-17 {
  &.force-font-size {
    font-size: 17px !important;
  }
  font-size: 17px;
}

.font-size-18 {
  &.force-font-size {
    font-size: 18px !important;
  }
  font-size: 18px;
}

.font-size-20 {
  &.force-font-size {
    font-size: 20px !important;
  }
  font-size: 20px;
}

.font-size-24 {
  &.force-font-size {
    font-size: 24px !important;
  }
  font-size: 24px;
}

.font-size-28 {
  font-size: 28px;
}

.font-size-25 {
  &.force-font-size {
    font-size: 25px !important;
  }
  font-size: 25px;
}

/*space separation system*/
.middle-padding-top {
  padding-top: 7.5px;
}

.middle-padding-bottom {
  padding-bottom: 7.5px;
}

.middle-padding-bottom-top,.middle-padding-top-bottom {
  padding-bottom: 7.5px;
  padding-top: 7.5px;
}

.middle-padding-right {
  padding-right: 7.5px;
}

.middle-padding-left {
  padding-left: 7.5px;
}

.middle-padding-sides {
  @extend .middle-padding-left;
  @extend .middle-padding-right;
}

.middle-padding {
  @extend .middle-padding-sides;
  @extend .middle-padding-bottom-top;
}

.padding-top {
  padding-top: 15px;
}

.padding-bottom {
  padding-bottom: 15px;
}

.padding-left {
  padding-left: 15px;
}

.padding-right {
  padding-right: 15px;
}

.padding {
  padding: 15px;
}

.padding-bottom-top, .padding-top-bottom {
  @extend .padding-bottom;
  @extend .padding-top;
}

.padding-sides {
  @extend .padding-left;
  @extend .padding-right;
}

.medium-padding-top {
  padding-top: 30px;
}

.medium-padding-bottom {
  padding-bottom: 30px;
}

.medium-padding-left {
  padding-left: 30px;
}

.medium-padding-right {
  padding-right: 30px;
}

.medium-padding {
  padding: 30px;
}

.medium-padding-bottom-top, .medium-padding-top-bottom {
  @extend .medium-padding-bottom;
  @extend .medium-padding-top;
}

.medium-padding-sides {
  @extend .medium-padding-left;
  @extend .medium-padding-right;
}

.medium-padding-sides-responsive {
  padding-left: 15px;
  padding-right: 15px;
  @media(min-width: 768px) {
    padding-left: 30px;
    padding-right: 30px;
  }
}

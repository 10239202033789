.cmp-survey-detail {


}

.star-satisfaction-wrapper {
    display: flex;
    justify-content: start;
    align-items: center;
}

.star-satisfaction.react-stars > span {
    margin-right: 5px;
}

fieldset#root_producto_satisfaccion_atributos_estrella > legend {
    font-family: Raleway,sans-serif;
    color: #0a3253;
    font-size: 17px;
    line-height: 1.3;
    font-weight: 700;
}

fieldset#root_producto_satisfaccion_atributos_estrella > div {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 0 !important;
    > label {
        font-size: 15px;
        margin-bottom: 0 !important;
        margin-right: 10px;
    }
}

div.reason_no_quality_info {
    margin-top: 20px !important;
}

div.reason_no_quality_info > div.field-radio-group {
    margin-top: 10px !important;
}

@media (max-width: 768px) {
    .star-satisfaction-wrapper.margin-left-auto > div {
        margin-left: auto;
    }
    .star-satisfaction-wrapper.center-stars-box {
        justify-content: center;
    }
    .star-satisfaction.react-stars > span {
        font-size: 35px !important;
    }
}

@media (max-width: 490px) {
    .star-satisfaction.react-stars > span {
        font-size: 25px !important;
    }
}
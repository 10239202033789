
.cmp-redirect-element {  
    width: 100%;
    a {
        padding: 12px 41px;
        background-color: $base-theme-color-blue;
        border: 1px solid $base-theme-color-blue;
        text-transform: uppercase;
        margin: auto;
        cursor: pointer;
        color: $base-theme-color-white !important;
        &:hover {
            opacity: 0.7;
        }
    }

}

.cmp-main-navbar {
    width: 100%;
    height: 56px;
    position: fixed;
    z-index: 10;
    top:0;
    background-color: $base-theme-color-darkblue;
    .autofact-logo {
        width: 122px;
        height: 55px;
    }

    @include respond-to('medium') {
        display: none;
    }
    .img-container{
        max-width: 130px;
        padding: 7.5px 0 0 7.5px;
    }
}